import gameDataJson from "@/utils/info/gameDataJson";

export default async function(query) {
    const OpenCC = await import(/* webpackChunkName: "opencc" */ "opencc-js");
    const converter = OpenCC.Converter({ from: "t", to: "cn" });
    const other_query = converter(query);

    const query_func = (game_info) =>
      game_info["name"]["zh-Hans"].includes(query) ||
      game_info["name"]["zh-Hans"].includes(other_query);

    const gameData = Object.values(gameDataJson).filter(query_func);

    return gameData;
}