<template>
  <b-container class="mt-3">
    <h3>搜索</h3>
    <div v-if="!show" class="text-center">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <GamesView
        :game_infos="gameData"
        :show_count="false"
        v-if="gameData.length > 0"
      />
      <ErrorComponent
        v-else
        error_code="404"
        error_message="搜索不到对应游戏，换个关键词试试？"
      />
    </div>
  </b-container>
</template>

<script>
import widescreen_mixin from "@/mixins/widescreen_mixin";
import ErrorComponent from "@/components/ErrorComponent";
import GamesView from "@/components/GamesView";

import searchGames from "@/utils/search";

export default {
  name: "search",
  mixins: [widescreen_mixin],
  metaInfo() {
    return {
      title: `搜索${this.query}`,
      meta: [
        {
          name: "description",
          content: `搜索${this.query} - 在线 DOS 游戏`,
        },
        { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
        {
          property: "og:title",
          content: `搜索${this.query} - 在线 DOS 游戏`,
        },
        {
          property: "og:description",
          content: `搜索${this.query} - 在线 DOS 游戏`,
        },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  data: function () {
    return {
      gameData: [],
      show: false,
    };
  },
  computed: {
    query: function () {
      return this.$route.query.q ?? "";
    },
  },
  watch: {
    $route() {
      this.searchGames().catch((err) => {
        console.error(err);
      });
    },
  },
  mounted() {
    this.searchGames().catch((err) => {
      console.error(err);
    });
  },
  components: {
    ErrorComponent,
    GamesView,
  },
  methods: {
    searchGames: async function () {
      const gameData = await searchGames(this.query);
      this.gameData = gameData;
      this.show = true;
    },
  },
};
</script>
