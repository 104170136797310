<template>
  <b-container class="mt-4">
    <b-row >
      <b-col sm="4" class="error-image-col mb-4">
        <img src="@/assets/img/crt_error.svg" alt="Error CRT Monitor" class="error-image">
      </b-col>
      <b-col sm="8" class="error-message-col mb-4">
        <h1 class="error-code">{{ error_code }}</h1>
        <h3 class="error-message">{{ error_message }}</h3>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "ErrorComponent",
  props: ["error_code", "error_message"],
};
</script>

<style scoped>
.error-image {
  flex: 1;
}

.error-image-col {
  display: flex;
}

.error-message-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.error-message {
  text-align: center;
}

.error-code {
  font-size: 6rem;
  font-weight: 500;
}
</style>